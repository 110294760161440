import React, { FC, memo, useCallback } from 'react';

import debounce from '@mui/utils/debounce';

export interface RunOnKeysProps extends React.HTMLAttributes<HTMLDivElement> {
  keysEvents: {
    keys: (
      | string
      | string[]
      | { which: number; key: string; code: string[] }
      | { which: number; key: string; code: string }
    )[];
    event: () => void;
  }[];
  children: JSX.Element | (JSX.Element | null)[];
  tabIndex?: number;
}

const allDownKeys = new Set();
const clearAllDownKeys = debounce(() => {
  allDownKeys.clear();
}, 5000);
let isPressedCombination = false;

export const RunOnKeys: FC<RunOnKeysProps> = memo(
  ({ keysEvents, children, tabIndex, ...props }) => {
    const runOnKeys = useCallback(
      (keys: any[], func: () => void) => {
        if (allDownKeys.size !== keys.length) return;
        const allMatch = keys.every((itm) => {
          let codes = itm?.code || itm;
          codes = Array.isArray(codes) ? codes : [codes];
          return !!codes.find((str: string) => allDownKeys.has(str));
        });
        if (!allMatch) return;
        if (!isPressedCombination) func();
        isPressedCombination = true;
      },
      [allDownKeys, isPressedCombination]
    );

    const onKeyDown = useCallback(
      (event: { code: string }) => {
        allDownKeys.add(event.code);
        keysEvents.forEach(({ keys, event }) => {
          runOnKeys(keys, event);
        });
        clearAllDownKeys();
      },
      [allDownKeys, keysEvents]
    );

    const onKeyUp = useCallback(
      (event: { code: string }) => {
        allDownKeys.delete(event.code);
        isPressedCombination = false;
      },
      [allDownKeys, isPressedCombination]
    );
    return (
      <div
        {...props}
        tabIndex={tabIndex ?? 0}
        style={{
          width: '100%',
          height: '100%',
        }}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      >
        {children}
      </div>
    );
  }
);
