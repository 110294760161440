import { useState } from "react";
import { container } from 'tsyringe';
import { isEmpty } from 'lodash';
import { BlockService } from '@/entities/Block/services/BlockService';
import { BlockStore } from '@/entities/Block/stores/BlockStore';
import { FlowCanvasService } from '@/entities/Flow/services/FlowCanvasService';
import Block from "@/entities/Block/types";

export const createCopyBlock = async (
  flowId: string,
  blockId: string,
  data: Block,
) => {
  const blockService = container.resolve(BlockService);
  const blockStore = container.resolve(BlockStore);
  const flowCanvasService = container.resolve(FlowCanvasService);

  await blockService.copyBlock(data, flowId);
  const { copyBlockNew: newBlock } = blockStore;
  const canvas = flowCanvasService.elements?.entities[blockId];

  if (isEmpty(newBlock) || isEmpty(canvas)) return;

  blockService.createBlock({
    ...newBlock,
    id: blockId,
  });
  flowCanvasService.updateElement(
    blockId,
    canvas?.top,
    canvas?.left,
    canvas.sx.height ?? undefined,
    canvas.sx.width ?? undefined
  );
};

/** Функция вставки блока с использованием горячих клавиш */
export const usePasteBlock = (
  flowId: string | undefined,
  blockId: string,
  setMessage: (str: string) => void,
) => async () => {
  if (!flowId) return;

  try {
    const clipBlock = await navigator.clipboard.readText();
    const { block } = JSON.parse(clipBlock);
    await createCopyBlock(flowId, blockId, block);
  } catch (err) {
    console.log(err);
    setMessage('Буфер копирования не доступен');
  }
};
