import { makeAutoObservable } from 'mobx';
import { injectable } from 'tsyringe';
import { FlowVersionStore } from '@/entities/FlowVersion';
import downloadJSON from "@/shared/lib/downloadJSON";

@injectable()
export class ExportVersionService {
  constructor(private flowVersionStore: FlowVersionStore) {
    makeAutoObservable(this);
  }

  exportVersion(versionId: string) {
    const version = this.flowVersionStore.versions?.entities[versionId];
    const fileName = `Версия ${version?.name || ''} потока ${version?.versionFlow?.name}`;
    downloadJSON(version, fileName);
  }
}
