import React from "react";
import { IconButton, Tooltip } from "@mui/material";

interface BlockActionBtnProps {
  tooltip: string;
  slug: string;
  icon: React.ReactNode;
  handler?: () => void;
}

export function BlockActionBtn ({
  tooltip,
  slug,
  icon,
  handler = undefined,
}: BlockActionBtnProps) {
  return (
    <Tooltip
      title={tooltip}
      placement="top"
    >
      <IconButton
        aria-label={slug}
        onClick={handler}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}