import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

function UploadFileJSON(props) {
  const { addFile, errHandler, children } = props
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        try {
          const resultParse = JSON.parse(reader.result);
          if (!addFile) return;
          addFile(resultParse);
        } catch (e) {
          if (!errHandler) return;
          errHandler(e);
        }
      }
      reader.readAsText(file)
    })
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

export default UploadFileJSON;