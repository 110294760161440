const downloadJSON = async (data: { [key: string]: any }, fileName: string) => {
  if (typeof data !== 'object') return

  const json = JSON.stringify(data, null, '  ');

  const blob = new Blob([json], { type: 'application/json' });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');

  link.href = url;
  link.download = fileName || 'unnamed';
  link.click();

  URL.revokeObjectURL(url);
};

export default downloadJSON;
